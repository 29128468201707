@import 'variables';

@mixin max-width($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin min-width($width) {
  @media only screen and (min-width: $width) {
    @content;
  }
}

@mixin max-height($height) {
  @media only screen and (max-height: $height) {
    @content;
  }
}

@mixin min-height($height) {
  @media only screen and (min-height: $height) {
    @content;
  }
}
