@font-face {
  font-family: Inter;
  src: url('/public/fonts/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('/public/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('/public/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('/public/fonts/Inter-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Inter;
  src: url('/public/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
