@import "/app/packages/a4bd-admin/src/stylesheets/data.scss";
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  height: 100%;
}

:root {
  --color-whisper: #ebebeb;
  --color-fail: #b71f1f;
  --color-white: #fff;
  --color-black: #151515;
  --color-blue: #1e4fbd;
  --color-black-science: #ea3147;
  --color-na-sporte: #5db64c;
  --color-grey-dark: #3b3b3b;
  --color-grey-light: #f0f0f0;
  --color-grey-light-dark: #747474;
  --color-grey-light-dark-op: rgb(116 116 116 / 30%);
  --color-blue-light: #dbe7ff;
  --color-blue-light-bg: #f2f6fc;
  --color-postnews-yellow: #fddc49;
  --color-social-vk: #07f;
  --color-social-telegram: #08c;
  --color-social-youtube: #f00;
  --color-social-ok: #ed812b;
  --color-social-twitter: #00acee;
  --color-social-whatsapp: #25d366;
  --color-alert: #278a1e;
  --color-icy-planes: #c2c7fb;
  --color-icy-planes-overlay: #c2c7fb33;
  --color-dark-grey: #505050;
  --color-grey: #bbb;
  --color-light-grey: #e9e9e9;
  --color-blue-bg: #f9fbfd;
  --color-brand-primary: #3a2d51;
  --color-brand-primary-hover: #615774;
  --color-brand-secondary: #f3f4fe;
  --color-brand-secondary-hover: #e7e9fe;
  --color-brand-secondary-active: #e0e3fd;
  --color-brand-secondary-disabled: #c2c7fb33;
  --color-black-242424: #242424;
  --color-text-grey-2: #a4a4a4;
}

.ant-menu-inline {
  .ant-menu-item::after {
    border-right: 3px solid var(--color-icy-planes-overlay);
  }
}

.ant-menu:not(.ant-menu-horizontal) {
  & li.ant-menu-item {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;
    height: 50px;
    color: var(--color-dark-grey);
    background-color: var(--color-blue-bg);

    &:active {
      color: var(--color-white);

      & .ant-menu-item-icon path {
        fill: var(--color-white);
      }
    }

    &.ant-menu-item-selected {
      color: var(--color-brand-primary);
      background-color: var(--color-icy-planes-overlay);

      & .ant-menu-item-icon path {
        fill: var(--color-brand-primary);
      }

      & .ant-menu-item-icon path[stroke] {
        stroke: var(--color-black);
        color: transparent;
      }
    }

    & .ant-menu-item-only-child {
      & .ant-menu-title-content {
        padding-left: 28px;
      }
    }
  }

  & li.ant-menu-submenu {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 0;

    & .ant-menu-submenu-title {
      height: 50px;
    }

    & .ant-menu-submenu-arrow {
      display: none;
    }

    & .ant-menu-item-only-child {
      & .ant-menu-title-content {
        padding-left: 28px;
      }
    }
  }

  & ul.ant-menu-sub {
    background-color: transparent;
  }
}

.ant-layout {
  background: var(--color-white);
}

.ant-layout-sider-children {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  background: var(--color-blue-bg);
  box-shadow: -1px 0 10px 0 rgb(0 0 0 / 5%) inset;

  @include max-width($lg) {
    width: 100% !important;
  }
}

.ant-radio-group {
  border-radius: 4px !important;
}

.ant-radio-button-wrapper {
  border: 0 !important;
}

.ant-radio-button-wrapper::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper:focus-within {
  box-shadow: none !important;
}

.ant-radio-button-wrapper-checked {
  border-radius: 4px !important;
}

.public-DraftEditorPlaceholder-root {
  font-weight: 400;
  left: 0;
  position: absolute;
  color: var(--color-grey);
  pointer-events: none;
}

.public-DraftEditorPlaceholder-hasFocus {
  display: none;
}

.public-DraftStyleDefault-block {
  margin-bottom: 1em;

  span {
    span {
      color: var(--color-black);
    }
  }

  a {
    span {
      color: var(--color-brand-primary);
      text-decoration: underline;
    }
  }
}

.ant-card-actions {
  border-top: 2px solid var(--color-brand-primary);
}

.ant-dropdown-menu-item {
  padding: 10px 15px;

  & .ant-dropdown-menu-title-content {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
}

.ant-form-item-explain-error {
  padding-top: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: var(--color-fail);
}

.ant-list-item-extra {
  margin: 0 !important;
}

.pn_notification {
  background: var(--color-black);
  padding: 10px 40px;

  & .ant-notification-notice-close {
    top: 12px;
  }

  & .ant-notification-notice-message {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }

  & span {
    color: var(--color-white);
  }
}

.ant-modal {
  top: 75px;
}

.ant-btn {
  &::after {
    all: unset;
  }
}

.ant-select-dropdown {
  overflow: unset;
}
